import { CommonModule } from '@angular/common';
import {
  Component, computed, ElementRef, HostListener, input, model, signal, viewChild,
} from '@angular/core';

import { DropdownOption, DropdownValue } from '../../models/field.model';
import { InlineSvgComponent } from '../inline-svg/inline-svg.component';

@Component({
  selector: 'ui-dropdown',
  imports: [ CommonModule, InlineSvgComponent ],
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss',
})
export class DropdownComponent {
  /** A unique name identifier for the dropdown component.
   *  The name is a string generated with a random hexadecimal number.
  */
  name = input<string>(`ui-dropdown-${(Math.round((Math.random() * 100000000)).toString(16))}`);
  /** The list of options available for selection in the dropdown. */
  options = input.required<DropdownOption[]>();
  /** The label to display for the dropdown when no value is selected. */
  placeholder = input<string>();
  /** The currently selected value of the dropdown */
  value = model<DropdownValue | undefined>();
  /** Signal that tracks whether the dropdown is open or closed. */
  isOpen = signal<boolean>(false);

  /** Computed property that display the text for the dropdown. */
  displayText = computed(() =>
    this.options().find(v => v.value === this.value())?.label || this.placeholder()
  );

  /** Reference to the dropdown HTML element */
  dropdownContainer = viewChild<ElementRef | undefined>('dropdownContainer');

  @HostListener('document:click', [ '$event' ])
  onClickOutside(event: MouseEvent): void {
    if (
      this.dropdownContainer() &&
      !this.dropdownContainer()?.nativeElement.contains(event.target) &&
      event.target instanceof HTMLElement
    ) {
      this.isOpen.set(false);
    }
  }

  /** Toggles the dropdown between open and closed states. */
  onOpen(): void {
    this.isOpen.update(isOpen => !isOpen);
  }

  /** Handles the selection of an option from the dropdown. */
  onSelect(item: DropdownOption): void {
    this.value.set(item.value);
    this.isOpen.set(false);
  }
}
