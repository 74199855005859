import { CommonModule } from '@angular/common';
import { Component, input, model } from '@angular/core';

import { TranslocoModule } from '@jsverse/transloco';

import { ButtonCloseComponent } from '../button-close/button-close.component';
import { InlineSvgComponent } from '../inline-svg/inline-svg.component';

@Component({
  selector: 'ui-popover',
  imports: [ CommonModule, TranslocoModule, InlineSvgComponent, ButtonCloseComponent ],
  templateUrl: './popover.component.html',
  styleUrl: './popover.component.scss',
})
export class PopoverComponent {
  /** The color of the popover. */
  color = input<'default' | 'blue'>('default');
  /** The text content displayed within the popover. */
  text = input.required<string>();
  /** The position of the popover relative to the target element. */
  position = input.required<'top' | 'bottom' | 'start' | 'end'>();
  /** Determines whether the popover is visible. */
  visible = model<boolean>(true);

  /**
   * Hides the popover when clicked.
   */
  onClickHide(): void {
    this.visible.set(false);
  }
}
