<div class="container">
  @if (!vertical()) {
  <ui-inline-svg
    name="instaclause-horizontal-logo"
    class="logo"
    [class.invert]="invert()"
    [class.vertical]="vertical()"></ui-inline-svg>
  } @else {
  <ui-inline-svg
    name="instaclause-vertical-logo"
    class="logo"
    [class.invert]="invert()"
    [class.vertical]="vertical()"></ui-inline-svg>
  }
</div>
