import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';


import { InlineSvgComponent } from '../inline-svg/inline-svg.component';
import { TooltipDirective } from '../tooltip/tooltip.directive';

export interface ButtonGroupItem {
  tooltipText?: string;
  icon: string;
  action: ($event: MouseEvent) => void;
}

@Component({
  selector: 'ui-button-group',
  imports: [ CommonModule, InlineSvgComponent, TooltipDirective ],
  templateUrl: './button-group.component.html',
  styleUrl: './button-group.component.scss',
})
export class ButtonGroupComponent {
  /** The buttons to display within the button group. */
  buttons = input<ButtonGroupItem[]>([]);
}
