<table class="table" [ngStyle]="cssStyle()">
  @if (config().filter) {
  <ui-table-filter
    class="table-head"
    [config]="config().filter"
    [(filterState)]="filterState"
    (checkAll)="onChangeCheckAll($event)"
    />
  }

  <tbody class="table-body">
    @if (data().length > 0) {
      @for (item of data(); track $index) {
        <tr
        class="table-row color-{{ config().background }}"
        [class.selected]="checkedValues() | hasValue: item">
          @if (config().checkbox) {
            <ui-checkbox
              class="table-row-checkbox"
              [value]="checkedValues() | hasValue: item"
              (valueChange)="onChangeUpdateCheckbox(item)"
              >
            </ui-checkbox>
          }

          <ng-container
            [ngTemplateOutlet]="template()"
            [ngTemplateOutletContext]="{ $implicit: item }">
          </ng-container>

          <button type="button" class="table-row-button" (click)="onClickOpenOptions(item)">
            <ui-inline-svg [name]="'more-horizontal'"></ui-inline-svg>
          </button>
        </tr>
      }
    }
    @else {
      <div class="table-empty">
        <h1 class="table-empty-title">{{ 'CONTRACT.LIST.EMPTY.TEXT1' | transloco }}</h1>
        <h1 class="table-empty-title">{{ 'CONTRACT.LIST.EMPTY.TEXT2' | transloco }}</h1>
        <ui-button size="large" color="secondaryOnLight">
          {{ 'CONTRACT.LIST.EMPTY.BUTTON' | transloco }}
        </ui-button>
      </div>
    }
  </tbody>
</table>
