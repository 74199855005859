import { CommonModule } from '@angular/common';
import { Component, model } from '@angular/core';

import { TabItemModel } from '../../models/tab.model';

@Component({
  selector: 'ui-tabs',
  imports: [ CommonModule ],
  templateUrl: './tabs.component.html',
  styleUrl: './tabs.component.scss',
})
export class TabsComponent {
  /** An array of tab items that define the available tabs. */
  tabs = model<TabItemModel[]>();

  /** A method that switches to the selected tab. */
  switchtab(value: TabItemModel): void {
    this.tabs.update((tab) =>
      tab?.map((item) => ({
        ...item,
        selected: item === value,
      }))
    );
  }
}
