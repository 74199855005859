import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, inject, input } from '@angular/core';

import { ButtonCloseComponent } from '../button-close/button-close.component';
import { InlineSvgComponent } from '../inline-svg/inline-svg.component';


@Component({
  selector: 'ui-modal',
  imports: [ CommonModule, ButtonCloseComponent, InlineSvgComponent ],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss',
})
export class ModalComponent {
  /** The background color of the modal. */
  backgroundColor = input<'default' | 'gray' | 'blue'>('default');

  /** Align the modal content to its center. */
  centered = input<boolean>(false);

  /** The SVG icon to be displayed in the modal's title. */
  titleIcon = input<string>();

  /** Sets a loading state, disabling interactions with the modal content. */
  loading = input<boolean>(false);

  /** A reference to the dialog service managing this modal. */
  private dialogRef = inject(DialogRef);

  private confirmationMessage =
    'Are you sure you want to close this dialog? Your changes will not be saved.';

  /** Closes the modal dialog. */
  onClose(): void {
    const close = !this.dialogRef.disableClose || confirm(this.confirmationMessage);
    if (close) {
      this.dialogRef.close();
    }
  }
}
