<div class="search">
  <ui-inline-svg class="search-icon" name="search"></ui-inline-svg>
  <input 
    #inputElement
    class="search-input"
    type="text"
    [placeholder]="'CONTRACT.LIST.SEARCH' | transloco"
    [(ngModel)]="value"
    (blur)="onBlurEvent($event)"
    (focus)="focus.emit($event)"
    (mousedown)="onTextClick($event)"
    />

    <div class="search-clear" [style.visibility]="value() ? 'visible' : 'hidden'">
      <ui-button-close (click)="onClickClear($event)"></ui-button-close>
    </div>
</div>
