import { CommonModule } from '@angular/common';
import {
  Component,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { Subscription } from 'rxjs';

import { Toast, ToastType } from '../../models/toast.model';
import { ButtonComponent } from '../button/button.component';
import { ButtonCloseComponent } from '../button-close/button-close.component';
import { InlineSvgComponent } from '../inline-svg/inline-svg.component';

import { ToastService } from './toast.service';

@Component({
  selector: 'ui-toast',
  imports: [ CommonModule, InlineSvgComponent, ButtonComponent, ButtonCloseComponent ],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
})
export class ToastComponent implements OnInit, OnDestroy {
  /** The current list of toasts to be displayed. */
  toasts: Toast[] = [];
  /** Possible types of toast notifications */
  toastType = ToastType;

  /** A reference to the toast service managing this component. */
  private toastService = inject(ToastService);
  private subscription = new Subscription();

  ngOnInit() {
    this.subscription = this.toastService.toasts$.subscribe((toasts: Toast[]) => {
      this.toasts = toasts;
    });
  }

  /** Notification that should be removed from the list. */
  removeToast(toast: Toast): void {
    this.toastService.removeToast(toast);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
