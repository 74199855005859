<div class="substitute" [class.isOpen]="isOpen()" #dropdownContainer>
  <button class="substitute-button" (click)="onOpen()">
    <span
      class="substitute-text"
    >
      {{ displayText() }}
    </span>
    <ui-inline-svg class="substitute-icon" [name]="'tooltip-arrow-top'"></ui-inline-svg>
  </button>
  <div class="substitute-list">
    <div
      class="substitute-list-container"
      role="listbox"
      aria-activedescendant="option-1"
    >
      <div
        class="substitute-option"
        role="option"
        [id]="'option-' + i"
        [attr.aria-selected]="item.value === value()"
        *ngFor="let item of options(); let i = index"
        (click)="onSelect(item)"
      >
        <span
          class="substitute-text"
          role="presentation"
        >
          {{ item.label }}</span>
      </div>
    </div>
  </div>
</div>
