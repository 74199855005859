<div class="container color-{{ backgroundColor() }}">
  <header class="header color-{{ backgroundColor() }}">
    <div class="title">
      @if (titleIcon()) {
        <ui-inline-svg [name]="titleIcon() ?? ''"></ui-inline-svg>
      }
      <ng-content select="h5"></ng-content>
    </div>
    <ng-content select="h2"></ng-content>
    <ui-button-close type="invert" size="large" (click)="onClose()"></ui-button-close>
  </header>
  <main class="main" [class.centered]="centered()">
    @if (loading()) {
      <div class="loading">
        <ui-inline-svg name="spinner" class="loading-icon"></ui-inline-svg>
      </div>
    }
    <ng-content select="ng-container"></ng-content>
  </main>
</div>
