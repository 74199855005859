import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  linkedSignal,
  model,
  output,
} from '@angular/core';

import { TranslocoModule } from '@jsverse/transloco';

import { Column, FilterState, TableFilter } from '../../../models/table.model';
import { CheckboxComponent } from '../../checkbox/checkbox.component';
import { InlineSvgComponent } from '../../inline-svg/inline-svg.component';

import { SetToArray } from './set-to-array.pipe';

@Component({
  selector: 'ui-table-filter',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    InlineSvgComponent,
    CheckboxComponent,
    SetToArray,
  ],
  templateUrl: './table-filter.component.html',
  styleUrl: './table-filter.component.scss',
  providers: [],
})
export class TableFilterComponent {
  config = model.required<TableFilter>();
  filterState = model<FilterState | undefined>();
  checkAll = output<boolean | string>();

  sort = computed(() => ({
    sortBy: this.filterState()?.sortBy,
    sortAscending: this.filterState()?.sortAscending,
  }));

  filters = linkedSignal(() => {
    const filters = this.filterState()?.query?.split(';');

    const selected = filters?.reduce((acc, filter) => {
      const [ key, value ] = filter.split(':');
      if (!key || !value) {
        return acc;
      }

      return { ...acc, [key]: value };
    }, {} as Record<string, string | null>);

    return {
      opened: '',
      selected: selected || {},
    };
  });

  onClickCheckAll(event: boolean | string): void {
    this.checkAll.emit(event);
  }

  onClickFilter(column: Column, value: string | null): void {
    this.filterState.update((filterState) => {
      const query = filterState?.query || '';
      const filters = query.split(';');

      const newFilters = filters.filter((filter) => filter && !filter.startsWith(column.sortBy));
      if (value) {
        newFilters.push(`${column.sortBy}:${value}`);
      }

      return {
        ...filterState,
        query: newFilters.join(';'),
      };
    });
  }

  onClickSort(column: Column | null): void {
    if (!column || !column.sortable) {
      return;
    }

    this.filterState.update((filterState) => ({
      ...filterState,
      sortAscending: column.sortBy === filterState?.sortBy ? !filterState.sortAscending : false,
      sortBy: column.sortBy,
    }));
  }

  onOpenFilter(column: Column): void {
    this.filters.update(state => ({
      ...state,
      opened: state.opened ? '' : column.sortBy,
    }));
  }
}
