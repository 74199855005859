import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';

import { InlineSvgComponent } from '../inline-svg/inline-svg.component';

@Component({
  selector: 'ui-tooltip',
  imports: [ CommonModule, InlineSvgComponent ],
  templateUrl: './tooltip.component.html',
  styleUrl: './tooltip.component.scss',
})
export class TooltipComponent {
  /** The text content displayed within the tooltip. */
  text = input<string>();
  /** The position of the tooltip relative to the target element. */
  position = input.required<'top' | 'bottom' | 'start' | 'end'>();
  /** The maximum width of the tooltip. */
  maxWidth = input.required<string>();
  /** Determines whether the tooltip should have an arrow. */
  arrow = input.required<boolean>();
  /** Determines whether the tooltip is visible. */
  isOpen = input<boolean>();
}
