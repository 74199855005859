<div class="select" #selectField>
  <div class="select-container" (click)="onOpen()" [class.isDisabled]="disabled()">
    <span class="select-container-text">{{ displayText() || placeholder() }}</span>
    <ui-inline-svg class="select-container-icon" [name]="'tooltip-arrow-top'"></ui-inline-svg>
  </div>

  @if (isOpen() && !disabled()) {
  <ul class="select-options">
    @for (option of options(); track $index) {
    <li class="select-options-list" (click)="onSelect(option)">
      {{ option.label }}
    </li>
    }
  </ul>
  }
</div>
