<thead class="container" >
  <ui-checkbox
    class="container-checkbox"
    [style.visibility]="config().checkbox ? 'visible' : 'hidden'"
    (valueChange)="onClickCheckAll($event)"
    />

  @for(column of config().columns; track $index ) {
    <div class="container-column">
      @if(column) {
         @if (!filters().selected[column.sortBy]) {
          <div (mouseenter)="onOpenFilter(column)" (click)="onClickSort(column)" class="container-column-text">
            <span> {{ column.label | transloco }}</span>
            @if(column.values?.size){
              <ui-inline-svg class="container-column-icon" name="tooltip-arrow-top"></ui-inline-svg>
            }
          </div>
    
          @if (column.sortBy === sort().sortBy) {
            <ui-inline-svg
              class="container-column-sort"
              [name]="filterState()?.sortAscending ? 'arrow-up' : 'arrow-down'"
              />
          }
            
        } @else {
          <div class="container-column-value" (click)="onClickFilter(column, null)">
            <span class="container-column-value-text">{{ filters().selected[column.sortBy] }}</span>
            <ui-inline-svg class="container-column-value-close" name="close"></ui-inline-svg>
          </div>
        }
        @if (column.sortBy === filters().opened && column.values) {
          <div class="container-column-filter" (mouseleave)="onOpenFilter(column)">
            <div class="container-column-filter-dropdown">
              <hr class="container-column-filter-dropdown-separator" />
              @for (item of column.values | setToArray | slice:0:8; track $index) {
                <div
                  class="container-column-filter-dropdown-list"
                  (click)="onClickFilter(column, item)">
                  {{ item }}
                </div>
              }
            </div>
          </div>
        }
      }
    </div>
  }
</thead>
