import { CommonModule } from '@angular/common';
import {
  Component, computed, output, input, model, signal,
  ViewChild,
  ElementRef,
  HostListener,
} from '@angular/core';

import { DropdownOption, DropdownValue } from '../../../models/field.model';
import { InlineSvgComponent } from '../../inline-svg/inline-svg.component';

@Component({
  selector: 'ui-select-field',
  imports: [ CommonModule, InlineSvgComponent ],
  templateUrl: './select-field.component.html',
  styleUrl: './select-field.component.scss',
})
export class SelectFieldComponent {
  @ViewChild('selectField') selectField: ElementRef | undefined;

  /** Output bind value of the selected option in the dropdown list. */
  select = output<DropdownValue>();
  /** Array of options available in the dropdown, each with a label and value. */
  options = input.required<DropdownOption[]>();
  /** Output bind value. */
  value = model<DropdownValue>();
  /** The placeholder text displayed when no option is selected. */
  placeholder = input<string>('');
  /** Signal that defines the opening and closing state of the component. */
  isOpen = signal(false);

  /** Label of the selected option that appears on the component. */
  displayText = computed(() => this.options().find((v) => v.value === this.value())?.label || '');

  /**  Determines whether the select dropdown is disabled. */
  disabled = input<boolean>(false);

  @HostListener('document:click', [ '$event' ])
  onClickOutside(event: MouseEvent): void {
    if (
      this.selectField &&
      !this.selectField.nativeElement.contains(event.target) &&
      event.target instanceof HTMLElement
    ) {
      this.isOpen.set(false);
    }
  }

  /** Method that opens and closes the list of options. */
  onOpen(): void {
    this.isOpen.set(!this.isOpen());
  }

  /** Method that handles onOpen and set the model and input of the selected option. */
  onSelect(option: DropdownOption): void {
    this.onOpen();
    this.value.set(option.value);
    this.select.emit(option.value);
  }
}
