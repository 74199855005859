import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';

import { InlineSvgComponent } from '../inline-svg/inline-svg.component';

@Component({
  selector: 'ui-logo',
  imports: [ CommonModule, InlineSvgComponent ],
  templateUrl: './logo.component.html',
  styleUrl: './logo.component.scss',
})
export class LogoComponent {
  /** Determines if the logo should be displayed in an inverted color scheme. */
  invert = input<boolean>(false);
  /** Determines if the logo should be displayed in a vertical orientation. */
  vertical = input<boolean>(false);
}
