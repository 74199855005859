<label class="radio">
  <input 
    class="radio-container" 
    type="radio" 
    [checked]="isChecked()"
    (change)="toggleChecked()"
    (click)="onClickUnCheck()"
    [name]="name()">
  <ui-inline-svg class="radio-icon" [name]="iconName()"></ui-inline-svg>
  <span class="radio-text">
    <ng-content></ng-content>
  </span>
</label>
