import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  input,
  model,
  output,
  signal,
  TemplateRef,
} from '@angular/core';

import { TranslocoModule } from '@jsverse/transloco';

import { FilterState, TableConfig, TableData } from '../../../models/table.model';
import { HasValuePipe } from '../../../pipes/has-value.pipe';
import { ButtonComponent } from '../../button/button.component';
import { CheckboxComponent } from '../../checkbox/checkbox.component';
import { InlineSvgComponent } from '../../inline-svg/inline-svg.component';
import { TableFilterComponent } from '../table-filter/table-filter.component';

@Component({
  selector: 'ui-table',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    ButtonComponent,
    CheckboxComponent,
    TableFilterComponent,
    InlineSvgComponent,
    HasValuePipe,
  ],
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
  providers: [],
})
export class TableLayoutComponent {
  config = input.required<TableConfig>();
  data = input<TableData<any>>([]);

  template = input.required<TemplateRef<any>>();

  checkedValues = model<any>([]);
  triggerOpenOptions = output<any>();

  filterState = model<FilterState | undefined>();

  currentOption = signal<any>(undefined);
  previousOption = signal<any>(undefined);
  check = false;

  cssStyle = computed(() => {
    const config = this.config();
    const grid = config.filter.grid || `repeat(${config.columns}, 1fr)`;
    const checkbox = config.checkbox ? 'min-content ' : '';

    return {
      'grid-template-columns': `${checkbox}${grid} min-content`,
    };
  });

  onChangeUpdateCheckbox(data: any): void {
    this.checkedValues.update((values: any[]) => {
      const index = values.indexOf(data);

      return index > -1 ? values.filter(item => item !== data) : [ ...values, data ];
    });
  }

  onChangeCheckAll(event: boolean | string): void {
    this.check = event as boolean;
    const values = event ? this.data() : [];
    this.checkedValues.set(values);
  }

  onClickOpenOptions(data: any): void {
    this.previousOption.set(this.currentOption());
    this.currentOption.set(this.currentOption() === data ? null : data);
    this.triggerOpenOptions.emit(this.currentOption());
  }

}
