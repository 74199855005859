import { CommonModule } from '@angular/common';
import {
  Component, computed, input, model,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import { FieldState } from '../../../models/field.model';
import { InlineSvgComponent } from '../../inline-svg/inline-svg.component';

@Component({
  selector: 'ui-hour-field',
  imports: [ CommonModule, FormsModule, InlineSvgComponent ],
  templateUrl: './hour-field.component.html',
  styleUrl: './hour-field.component.scss',
})
export class HourFieldComponent {
  /** Defines the style of the hour field. */
  fieldState = FieldState;
  /** A unique name identifier for the hour component.
   *  The name is a string generated with a random hexadecimal number.
  */
  name = input<string>(`ui-hour-field-${(Math.round((Math.random() * 100000000)).toString(16))}`);
  /** Output bind value. */
  value = model<string>('');
  /** Defines the current style of the email field. */
  state = input<FieldState>(FieldState.Default);
  /** Alert text message to be displayed based on the value. */
  alert = input<string>();

  /** Computed property that returns the icon
   * that should be displayed based on the FieldState.
  */
  icon = computed(() => {
    switch (this.state()) {
      case FieldState.Success:
      case FieldState.Info:
        return 'success';
      case FieldState.Invalid:
        return 'error';
      default:
        return '';
    }
  });
}
