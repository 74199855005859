import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { InlineSvgComponent } from '../inline-svg/inline-svg.component';

@Component({
  selector: 'ui-drag',
  imports: [ CommonModule, InlineSvgComponent ],
  templateUrl: './drag.component.html',
  styleUrl: './drag.component.scss',
})
export class DragComponent {}
