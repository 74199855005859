import { CommonModule } from '@angular/common';
import { Component, computed, input } from '@angular/core';

import { InlineSvgComponent } from '../inline-svg/inline-svg.component';

@Component({
  selector: 'ui-button-alert',
  imports: [ CommonModule, InlineSvgComponent ],
  templateUrl: './button-alert.component.html',
  styleUrl: './button-alert.component.scss',
})
export class ButtonAlertComponent {
  /** Represents whether the notifications are visible or hidden. */
  open = input<boolean>(false);
  /** Represents the number of notifications. */
  notifications = input<number>(0);

  /** Computed property that returns whether the notification count
 * is greater than 9. This can be used to style or display
 * notifications with a badge indicating 'double digits'.
 */
  doubleDigits = computed(() => this.notifications() > 9);
}
