import { CommonModule } from '@angular/common';
import {
  Component, computed, input, model,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import { FieldState } from '../../../models/field.model';
import { InlineSvgComponent } from '../../inline-svg/inline-svg.component';

@Component({
  selector: 'ui-email-field',
  imports: [ CommonModule, FormsModule, InlineSvgComponent ],
  templateUrl: './email-field.component.html',
  styleUrl: './email-field.component.scss',
})
export class EmailFieldComponent {
  /** Defines the style of the email field. */
  fieldState = FieldState;
  /** A unique name identifier for the email component.
   *  The name is a string generated with a random hexadecimal number.
  */
  name = input<string>(`ui-email-field-${(Math.round((Math.random() * 100000000)).toString(16))}`);
  /** Output bind value. */
  value = model<string | undefined>('');
  /** Defines the current style of the email field. */
  state = input<FieldState>(FieldState.Default);
  /** Defines the placeholder  of the email field. */
  placeholder = input<string>('');
  /** Alert text message to be displayed based on the value. */
  alert = input<string>();

  /** Computed property that returns the icon
   * that should be displayed based on the FieldState.
  */
  icon = computed(() => {
    switch (this.state()) {
      case FieldState.Success:
      case FieldState.Info:
        return 'success';
      case FieldState.Invalid:
        return 'error';
      default:
        return '';
    }
  });

  /** Method that checks if the email value is valid. */
  verifyEmail(): void {
    const valid = /^[a-z0-9-_.]+@[a-z0-9-_.]+\.[a-z0-9]{2,}$/.test(this.value() ?? '');

    if (!valid) {
      this.value.set('');
    }
    // this.state = valid ? FieldState.Success : FieldState.Invalid;
  }
}
