@if (visible()) {
  <div [ngClass]="position()" class="popover color-{{ color() }}"
  >
    <div class="content">
      @if (['start', 'top'].includes(position())) {
      <ui-button-close (click)="onClickHide()" class="content-button" type="invert"></ui-button-close>
      }
  
      {{ text() | transloco}}
  
      @if (['end', 'bottom'].includes(position())) {
      <ui-button-close (click)="onClickHide()"  class="content-button" type="invert"></ui-button-close>
      }
    </div>
  
    <ui-inline-svg
      class="arrow"
      [style.color]="color() === 'default' ? 'white' : '#021C52'"
      [class]="position()"
      [name]="'tooltip-arrow-' + position()"></ui-inline-svg>
  </div>  
}
