/* eslint-disable @angular-eslint/no-output-native */
import { CommonModule } from '@angular/common';
import {
  Component, ElementRef, model, output, viewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslocoModule } from '@jsverse/transloco';
import { Subscription, timer } from 'rxjs';

import { ButtonCloseComponent } from '../../button-close/button-close.component';
import { InlineSvgComponent } from '../../inline-svg/inline-svg.component';


@Component({
  selector: 'ui-search-field',
  imports: [ CommonModule, FormsModule, TranslocoModule, InlineSvgComponent,
    ButtonCloseComponent ],
  templateUrl: './search-field.component.html',
  styleUrl: './search-field.component.scss',
  providers: [],
  host: {
    '(mousedown)': 'onClick($event)',
    '(keydown)': 'onEscape($event)',
  },
})
export class SearchFieldComponent {

  /** A model representing the value of the search input. */
  value = model<string>();

  /** Event triggered when the component is focused. */
  focus = output<FocusEvent>();

  /** Event triggered when the component loses focus */
  blur = output<FocusEvent>();

  private inputElement = viewChild<ElementRef<HTMLInputElement>>('inputElement');

  private subscription: Subscription | null = null;

  /** A method triggered when the clear button is clicked, resetting the search input value. */
  onClickClear(event: MouseEvent): void {
    event.stopImmediatePropagation();
    this.inputElement()?.nativeElement.focus();
    this.value.set(undefined);
    this.subscription?.unsubscribe();
  }

  /** A method triggered when the search input loses focus. */
  onBlurEvent(event: FocusEvent): void {
    this.subscription = timer(100).subscribe(() => {
      this.blur.emit(event);
    });
  }

  /** A method that remove focus from the input when the escape key is pressed. */
  onEscape(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.inputElement()?.nativeElement.blur();
    }
  }

  /** Method triggered when clicking inside the HTML input element.
   * Prevents the trigger of `onClick`.
   * Necessary for selecting text inside the input.
  */
  onTextClick(event: MouseEvent): void {
    event.stopImmediatePropagation();
  }

  /** A method to focus the search input when the search field is clicked. */
  onClick(event: MouseEvent): void {
    event.preventDefault();
    this.subscription?.unsubscribe();

    this.inputElement()?.nativeElement.focus();
  }
}

