@if (buttons().length) {
  <div class="button-group">
    @for (button of buttons(); track $index) {
      <button
        class="button-group-button"
        uiTooltip
        [tooltipText]="button.tooltipText"
        (click)="button.action($event)"
      >
        <ui-inline-svg
          [name]="button.icon"
          class="button-group-button-icon"></ui-inline-svg>
      </button>
    }
  </div>
}
