import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  input,
  model,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

import { FieldState } from '../../../models/field.model';
import { InlineSvgComponent } from '../../inline-svg/inline-svg.component';


@Component({
  selector: 'ui-integer-field',
  imports: [
    CommonModule,
    FormsModule,
    InlineSvgComponent,
    NgxMaskDirective,
  ],
  providers: [
    provideNgxMask(),
  ],
  templateUrl: './integer-field.component.html',
  styleUrl: './integer-field.component.scss',
})
export class IntegerFieldComponent {
  /** Defines the style of the iban field. */
  fieldState = FieldState;
  /** A unique name identifier for the iban component.
   *  The name is a string generated with a random hexadecimal number.
  */
  name = input<string>(`ui-integer-field-${(Math.round((Math.random() * 100000000)).toString(16))}`);
  /** Output bind value. */
  value = model<string>();
  /** Defines the current style of the integer field. */
  state = input<FieldState>(FieldState.Default);
  /** Defines the placeholder of the integer field. */
  placeholder = input<string>('');
  /** Alert text message to be displayed based on the value. */
  alert = input<string>();

  /** Computed property that returns the alert icon
   * that should be displayed based on the FieldState.
  */
  alertIcon = computed(() => {
    switch (this.state()) {
      case FieldState.Success:
      case FieldState.Info:
        return 'success';
      case FieldState.Invalid:
        return 'error';
      default:
        return '';
    }
  });

  /** Method that formats the value. */
  toString(value: string | number | undefined | null): string {
    const formattedValue = String(value).split(' ').join('');

    if (String(value).includes('.') && String(value).split('.').length === 2) {
      const decimal = String(value).split('.')[1]?.length;

      if (decimal && decimal > 2) {
        return parseFloat(formattedValue).toFixed(2);
      }
    }

    return formattedValue;
  }
}
