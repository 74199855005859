import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'ui-section',
  imports: [ CommonModule ],
  templateUrl: './section.component.html',
  styleUrl: './section.component.scss',
  providers: [],
})
export class SectionComponent {
  /** Select the number of columns to display: 1 or 2. */
  column = input<1 | 2>(2);
  /** Toggle whether the title should be sticky while scrolling. */
  sticky = input<boolean>(false);
}
