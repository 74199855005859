import { CommonModule } from '@angular/common';
import {
  Component, computed, input, model,
} from '@angular/core';

import { InlineSvgComponent } from '../inline-svg/inline-svg.component';

@Component({
  selector: 'ui-checkbox',
  imports: [ CommonModule, InlineSvgComponent ],
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.scss',
})
export class CheckboxComponent {
  /** A unique name identifier for the checkbox component.
   *  The name is a string generated with a random hexadecimal number.
   */
  name = input<string>(`ui-checkbox-${Math.round(Math.random() * 100000000).toString(16)}`);
  /** The value of the checked item. */
  value = model<boolean | 'partial'>(false);
  /** Computed property that returns the icon
   * that should be displayed based on its value.
   */
  iconName = computed(() => {
    if (this.value() === 'partial') {
      return 'checkbox-indeterminate';
    }

    return this.value() ? 'checkbox-selected' : 'checkbox-unselected';
  });

  /** A method that toggles the checkmark of the item. */
  toggleChecked(): void {
    this.value.set(!this.value());
  }
}
