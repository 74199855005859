import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  inject,
  signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslocoModule } from '@jsverse/transloco';

import { ButtonColors, ButtonComponent } from '../button/button.component';
import { ModalComponent } from '../modal/modal.component';

interface AlertComponentDataButton {
  label: string;
  color?: ButtonColors;
  action: () => any;
}

export interface AlertComponentData {
  title: string;
  message: string;
  buttons?: AlertComponentDataButton[];
}

@Component({
  selector: 'ui-alert',
  imports: [ CommonModule, TranslocoModule, FormsModule, ModalComponent, ButtonComponent ],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss',
  standalone: true,
})
export class AlertComponent implements AfterViewInit {
  public data: AlertComponentData = inject<AlertComponentData>(DIALOG_DATA);
  public loaded = signal(false);

  private dialogRef = inject(DialogRef);

  ngAfterViewInit(): void {
    this.loaded.set(true);
  }

  executeAction(action: () => void): void {
    const result = action();
    this.dialogRef.close(result);
  }
}
