import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'setToArray',
})
export class SetToArray implements PipeTransform {
  transform(value: Set<any>): any[] {
    return Array.from(value);
  }
}
