import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'ui-page',
  imports: [ CommonModule ],
  templateUrl: './page.component.html',
  styleUrl: './page.component.scss',
  providers: [],
})
export class PageComponent {
  /** Determines whether the sidebar remains fixed while scrolling the content area. */
  fixed = input<boolean>(false);
  /** Sets the background color of the sidebar. Options include gray or white. */
  sidebarBackground = input<'white' | 'gray'>();
}
