<ui-modal
  class="modal"
  [attr.hidden]="loaded()"
  titleIcon="container"
  titleIcon="alert-triangle"
  backgroundColor="blue"
  [centered]="true"
>
  <h5>{{ data.title | transloco }}</h5>
  <ng-container>
    <div class="container">
      <div class="container-title">
        <h2>{{ data.message | transloco }}</h2>
      </div>

      @if (data.buttons?.length) {
        <div class="container-action">
          @for (button of data.buttons; track $index) {
            <ui-button
              [centered]="true"
              size="large"
              [color]="button.color || 'secondaryOnDark'"
              (click)="executeAction(button.action)">
              {{ button.label | transloco }}
            </ui-button>
          }
        </div>
      }
    </div>
  </ng-container>
</ui-modal>
