/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import {
  Component, computed, input, model,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import { InlineSvgComponent } from '../inline-svg/inline-svg.component';

@Component({
  selector: 'ui-radio-button',
  imports: [ CommonModule, InlineSvgComponent, FormsModule ],
  templateUrl: './radio-button.component.html',
  styleUrl: './radio-button.component.scss',
})
export class RadioButtonComponent<T> {
  /** A unique name identifier for the radio component.
   *  The name is a string generated with a random hexadecimal number.
   */
  name = input<string>(`ui-radio-${Math.round(Math.random() * 100000000).toString(16)}`);
  /** The option associated with this radio button. */
  option = input<T>();
  /** The current value of the radio button group. */
  value = model<T>();
  /** Returns 'radio-true' icon if the radio button is checked, otherwise 'radio-false'. */
  iconName = computed(() => {
    return this.isChecked() ? 'radio-true' : 'radio-false';
  });
  /** Option that defines if the radio should be clear. */
  allowUncheck = input<boolean>(false);

  /** Determines if the radio button is checked. */
  isChecked(): boolean {
    return this.value() === this.option();
  }

  /** Method that allows the seleted field to be clear. */
  onClickUnCheck(): void {
    if (this.allowUncheck() && this.isChecked()) {
      this.value.set(undefined);
    }
  }

  /** Sets the current value to the radio button's option. */
  toggleChecked(): void {
    this.value.set(this.option());
  }
}
