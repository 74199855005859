import { CommonModule } from '@angular/common';
import {
  Component, computed, input, model,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import { provideNgxMask, NgxMaskDirective } from 'ngx-mask';

import { FieldState } from '../../../models/field.model';
import { InlineSvgComponent } from '../../inline-svg/inline-svg.component';

@Component({
  selector: 'ui-currency-field',
  imports: [
    CommonModule,
    FormsModule,
    InlineSvgComponent,
    NgxMaskDirective,
  ],
  templateUrl: './currency-field.component.html',
  styleUrl: './currency-field.component.scss',
  providers: [
    provideNgxMask(),
  ],
})
export class CurrencyFieldComponent {
  /** Defines the style of the currency field. */
  fieldState = FieldState;
  /** A unique name identifier for the currency component.
   *  The name is a string generated with a random hexadecimal number.
  */
  name = input<string>(`ui-currency-field-${(Math.round((Math.random() * 100000000)).toString(16))}`);
  /** Output bind value. */
  value = model<string | undefined>('');
  /** Defines the current style of the currency field. */
  state = input<FieldState>(FieldState.Default);
  /** Defines the prefix  of the currency field. */
  prefix = input<string>('€');
  /** Defines the placeholder  of the currency field. */
  placeholder = input<string>('');
  /** Alert text message to be displayed based on the value. */
  alert = input<string>();
  /** Computed property that returns the icon
   * that should be displayed based on the FieldState.
  */
  icon = computed(() => {
    switch (this.state()) {
      case FieldState.Success:
      case FieldState.Info:
        return 'success';
      case FieldState.Invalid:
        return 'error';
      default:
        return '';
    }
  });

  /** Method that formats the value. */
  toFixed(value: string | number | undefined | null): number {
    const formattedValue = String(value).split(' ').join('');

    // if (String(value).includes('.') && String(value).split('.').length === 2) {
    //   const decimal = String(value).split('.')[1]?.length;

    //   if (decimal && decimal > 2) {
    //     return Number(parseFloat(formattedValue).toFixed(2));
    //   }
    // }

    return Number(formattedValue);
  }
}
